.sider {
	position: relative;
	z-index: 5;
	border-right: 1px solid #f0f0f0;
	border-top: 3px solid $c1;
	overflow-y: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	.logo-wrapper {
		padding: 30px 50px;
		border-bottom: 1px solid #f0f0f0;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	@media (max-width: 999px) {
		position: absolute !important;
		padding-top: 20px;
	}
}

.ant-layout-sider-zero-width-trigger {
	top: 0 !important;
	height: 64px !important;
	width: 64px !important;
	right: -64px !important;
	line-height: 64px !important;
	border-right: 1px solid #e5e5e5;
}
