.main-wrapper {
	.ant-layout {
		.ant-layout-content {
			min-height: auto;
		}
	}

	height: 100vh;

	.sider {
		height: 100%;

		position: fixed;
		left: 10px;
		top: 10px;
		bottom: 10px;
		height: auto;
		border: 1px solid #f9f9f9 !important;
		box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.08);
		border-radius: 10px;

		.ant-menu {
			//height: 100%;
		}

		.account {
			border-top: 1px solid #e5e5e5;
			padding: 20px;
			margin-top: 20px;

			.ant-btn {
				width: 100%;
			}
		}
	}

	.page-wrapper {
		padding-top: 20px;
		padding-right: 10px;

		@media (max-width: 999px) {
			padding: 20px;
		}
		@media (max-width: 699px) {
			padding: 10px;
		}
	}

	.site-layout-background {
		background: white;
		padding: 30px;
		box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);

		@media (max-width: 999px) {
			padding: 20px;
		}
		@media (max-width: 699px) {
			padding: 20px;
		}
	}
}

.main-wrapper > .ant-layout {
	padding-left: 300px;

	@media all and (max-width: 999px) {
		padding-left: 0;
	}
}

.main-wrapper .page-wrapper > .ant-layout-content {
	border: 1px solid #f1f1f1;
	border-radius: 10px;
}
